import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/Trainingtable";
import StarRateIcon from "@mui/icons-material/StarRate";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';




const Committees = () => {
  const training =[
    {
      title:"The institute has established an efficient and quick result based mechanism for students to express their grievances freely."

    },
    {
      title:"The cases will be attended promptly on receipt of written or oral grievance from the students."
    },
    {
     title:"To conduct enquiries into all grievances in an impartial manner and ensure justice to the effected persons."
    },
    {
     title:"To recommend remedial measures for avoiding similar grievances in future."
    
    },
    {
     title:"To work in coordination with other redressal forums like Anti-Ragging Committee and student welfare committee etc., arranges guest lectures, demonstrations etc. for increasing the awareness among all the stake holders."

    },
    {
      title:"Complaint or Suggestion Boxes have been installed in the College campus in which the Students, who want to remain anonymous, can put in writing their grievances and their suggestions for improving the academics/administration in the institution."

    },
    {
      title:"The cases will be attended promptly on receipt of grievances from the students."
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
          TRAINING & PLACEMENT CELL
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
          The function of the cell is to look into the complaints lodged by any student either online or offline and judge its merit. The Grievance cell is also empowered to look into matters of harassment.


          </p>
          
        </div>
      </div>
      {/* <div className="container">
        <Table />
      </div> */}
      {/* <iframe src="https://drive.google.com/file/d/1FcYGJkBYr2rm7E-T68zy71DSwW8-Gdnh/preview?usp=drive_link" width="98%" height="700px"></iframe> */}
      <div className="Course_pdf">
        <div>
          <h4>TRAINING & PLACEMENT CELL</h4>
          <p>Download the TRAINING & PLACEMENT CELL</p>
        </div>
        <div>
          <div>
            <a
              href="https://drive.google.com/file/d/1FcYGJkBYr2rm7E-T68zy71DSwW8-Gdnh/view"
              target="_blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50, color: "#020f4f" }} />
            </a>
          </div>
        </div>
      </div>
    
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {training.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
    </div>
  );
};

export default Committees;
