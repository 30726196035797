import React from "react";
import { Link } from "react-router-dom";
import "./footer.css"

import logo from "../../assets/smcelogo.png";
import pdf28 from "../../assets/pdfsmittapalli/NBA-LETTER-2022.pdf";
import pdf31 from "../../assets/pdfsmittapalli/LETTER-FROM-NAAC-1.pdf";
import pdf5 from "../../assets/pdfsmittapalli/Mentoring-System-2021-22.pdf";
import pdf29 from "../../assets/pdfsmittapalli/Sri-Mittapalli-College-of-Engineering20240131-1MBA.pdf";
import pdf30 from "../../assets/pdfsmittapalli/Sri-Mittapalli-College-of-Engineering20240131-ENGG.pdf";
// import pdf14 from "../../assets/pdfsmittapalli/EOA_Report_2019-20-4.PDF-1.pdf";
// import pdf15 from "../../assets/pdfsmittapalli/EOA_Report_2020-21-1.pdf";
// import pdf16 from "../../assets/pdfsmittapalli/EOA-Report_2018-19-1-1.pdf";
// import pdf17 from "../../assets/pdfsmittapalli/EOA-Report-21-22-1.PDF-1.pdf";
// import pdf18 from "../../assets/pdfsmittapalli/EOA-Report-2013-141-1.pdf";
// import pdf19 from "../../assets/pdfsmittapalli/EOA-Report-2014-15-1.pdf";
// import pdf20 from "../../assets/pdfsmittapalli/EOA-Report-2015-16-1.pdf";
// import pdf21 from "../../assets/pdfsmittapalli/EOA-Report-2016-17-1.pdf";
// import pdf22 from "../../assets/pdfsmittapalli/EOA-Report-2017-18-1.pdf";
// import pdf23 from "../../assets/pdfsmittapalli/2006-AICTE-approval-1-1.pdf";
// import pdf24 from "../../assets/pdfsmittapalli/aicte-approval-2008-9-1.pdf";
// import pdf25 from "../../assets/pdfsmittapalli/aicte-approval-2009-2010-1.pdf";
// import pdf26 from "../../assets/pdfsmittapalli/aicte-approval-2010-1-1.pdf";
// import pdf27 from "../../assets/pdfsmittapalli/AICTE-Revised-Approval-Letter-2012-2013-u9-1.pdf";
// import pdf35 from "../../assets/pdfsmittapalli/sanc-2017-18.pdf";
// import pdf36 from "../../assets/pdfsmittapalli/sanc-2018-19.pdf";
// import pdf37 from "../../assets/pdfsmittapalli/sanc-2019-20.pdf";
// import pdf38 from "../../assets/pdfsmittapalli/sanc-2020-21.pdf";

import pdf from "../../assets/pdfsmittapalli/2019-20-BOG.pdf";
import pdf1 from "../../assets/pdfsmittapalli/2018-19-BOG.pdf";
import pdf2 from "../../assets/pdfsmittapalli/2017-18-governingbody.pdf";
import pdf3 from "../../assets/pdfsmittapalli/2020-21-BOG.pdf";
import pdf4 from "../../assets/pdfsmittapalli/2021-22-BOG.pdf";

const Footer = () => {
  return (
    <div
      className="container-fluid bg-dark text-light footer pt-1 mt-0 wow fadeIn"
      data-wow-delay="0.1s" 
    >
      <div className="container">
        <div className="row ">
          {/* <div className="col-2">
            <img className="img_headlogo pl-6" src={logo} alt="logo-img" />
          </div> */}

          <div className="col-lg-3 ">
            <h4 className="text-white mb-3">Quick Links</h4>
            <Link className="btn btn-link" to="#">
              Results
            </Link>
            <Link className="btn btn-link" to="#">
              How to Reach
            </Link>
            <Link className="btn btn-link" to="#">
              Alumini
            </Link>
            <Link className="btn btn-link" to="#">
              News Letters
            </Link>

            <Link className="btn btn-link" to="#">
              Downloads
            </Link>
            <Link
              className="btn btn-link"
              to="https://smce.ac.in/wp-content/uploads/2022/10/Staff.pdf"
              target="blank"
            >
              Staff
            </Link>

            <Link
              className="btn btn-link"
              to="https://smce.ac.in/wp-content/uploads/2022/10/RTI.pdf"
              target="blank"
            >
              RTI
            </Link>
            <Link className="btn btn-link" to={pdf30} target="blank">
              NIRF Engineering Report
            </Link>
          </div>
          <div className="col-lg-3 ">
            <br />
            <br />
            <Link className="btn btn-link" to="#">
              Webmail
            </Link>
            <Link className="btn btn-link" to="/Administration/vision">
              Vision Mission
            </Link>

            <Link className="btn btn-link" to="#">
              Mandatory Disclosure{" "}
            </Link>

            <Link className="btn btn-link" to="/auditreports">
              Audit Reports
            </Link>
            <Link className="btn btn-link" to="#" target="blank">
              AICTE
            </Link>
            <Link className="btn btn-link" to={{ pdf31 }} target="blank">
              NAAC
            </Link>
            <Link className="btn btn-link" to={{ pdf28 }} target="blank">
              NBA
            </Link>
            <Link className="btn btn-link" to="/servicerules" target="blank">
              Service Rules
            </Link>
          </div>
          <div className="col-lg-3 ">
            <br />
            <br />

            <Link className="btn btn-link" to="#">
              Governing Body
            </Link>
            <Link className="btn btn-link" to={pdf5} target="blank">
              Mentoring system
            </Link>

            <Link className="btn btn-link" to={pdf29} target="blank">
              NIRF MBA Report
            </Link>
            <Link className="btn btn-link" to="feedback/aluminiFeedback">
              Alumni Feedback
            </Link>
            <Link className="btn btn-link" to="/feedback/employerFeedback">
              Employer Feedback
            </Link>
            <Link className="btn btn-link" to="/feedback/teacherFeedback">
              Parent Feedback
            </Link>
            <Link className="btn btn-link" to="/feedback/studentFeedback">
              Student Feedback
            </Link>
          </div>

          <div className="col-lg-3 ">
            <h4 className="text-white mb-3">
              <b>Address</b>
            </h4>
            <iframe
              className="footer_maps"
              src="https://maps.google.com/maps?q=Sri%20Mittapalli%20College%20Of%20Engineering%2C%20Tummalapalem%2C%20NH16%2C%20Guntur%2C%20Andhra%20Pradesh%20522233&t=m&z=10&output=embed&iwloc=near"
              frameborder="0"
              style={{ }}
              allowfullscreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
            {/* <img className="img-fluid p-1" src={logo} alt="logo-img" /> */}
            {/* <div className="col-lg-2 col-md-6">
            <h4 className="text-white mb-3">Newsletter</h4>
            <iframe
              className="position-relative rounded w-100 h-100"
              src="https://maps.google.com/maps?q=Sri%20Mittapalli%20College%20Of%20Engineering%2C%20Tummalapalem%2C%20NH16%2C%20Guntur%2C%20Andhra%20Pradesh%20522233&t=m&z=10&output=embed&iwloc=near"
              frameborder="0"
              style={{ minHeight: "100px", width: "200px" }}
              allowfullscreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
            <div
              className="position-relative "
              // style={{ maxWidth: "400px" }}
            >
              <input
                className="form-control border-0 w-100 "
                type="text"
                placeholder="Your email"
              />
              <button
                type="button"
                className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
              >
                SignUp
              </button>
            </div>
          </div> */}
            {/* <div className="row g-2 pt-2">
              <div className="col-4">
                <img
                  className="img-fluid bg-light p-1"
                  src={course1}
                  alt="course1-img"
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid bg-light p-1"
                  src={course2}
                  alt="course2-img"
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid bg-light p-1"
                  src={course3}
                  alt="course3-img"
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid bg-light p-1"
                  src={course2}
                  alt="course2-img"
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid bg-light p-1"
                  src={course3}
                  alt="course3-img"
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid bg-light p-1"
                  src={about}
                  alt="about-img"
                />
              </div>
            </div> */}
          </div>

          {/* <li>
                <Link to="#">AICTE ?</Link>
                <ul>
                  <li>
                    <Link to={pdf17} target="blank">
                      EOA Report 2021-22
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf15} target="blank">
                      EOA Report 2020-21
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf14} target="blank">
                      EOA Report 2019-20
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf16} target="blank">
                      EOA Report 2018-19
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf22} target="blank">
                      EOA Report 2017-18
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf21} target="blank">
                      EOA Report 2016-17
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf20} target="blank">
                      EOA Report 2015-16
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf19} target="blank">
                      EOA Report 2014-15
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf18} target=" blank">
                      EOA Report 2013-14
                    </Link>
                  </li>

                  <li>
                    <Link to={pdf27} target="blank">
                      AICTE 2012-13
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf26} target="blank">
                      AICTE 2010-11
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf25} target="blank">
                      AICTE 2009-10
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf24} target="blank">
                      AICTE Approval 2008-9
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf23} target="blank">
                      AICTE 2006
                    </Link>
                  </li>

                  <li>
                    <Link to="/home">AICTE-EOAS?</Link>
                    <ul>
                      <li>
                        <Link to={pdf38} target="blank">
                          2021-22
                        </Link>
                      </li>
                      <li>
                        <Link to={pdf37} target="blank">
                          2019-20
                        </Link>
                      </li>
                      <li>
                        <Link to={pdf36} target="blank">
                          2018-19
                        </Link>
                      </li>
                      <li>
                        <Link to={pdf35} target="blank">
                          2017-18
                        </Link>
                      </li>
                      <li>
                        <Link to={pdf38} target="blank">
                          2020-21
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li> */}
          <hr />
          <div className="col-lg-2">

          </div>
          <div className="col-lg-4 ">
            <h5>Social Media Links</h5>
                <div className="d-flex pt-4 ">
                  <Link
                    className="btn btn-outline-light btn-social"
                    to="https://twitter.com/smcengg/status/1091256873553141760?lang=en"
                    target="blank"
                  >
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link
                    className="btn btn-outline-light btn-social"
                    to="https://www.facebook.com/smcengg/"
                    target="blank"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                  <Link className="btn btn-outline-light btn-social" to="#">
                    <i className="fab fa-youtube"></i>
                  </Link>
                  <Link
                    className="btn btn-outline-light btn-social"
                    to="https://www.linkedin.com/in/sri-mittapalli-college-of-engineering-bb311723b?trk=blended-typeahead"
                    target="blank"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </Link>
                  <Link
                    className="btn btn-outline-light btn-social"
                    to="https://www.instagram.com/mittapalli_rockerz/"
                    target="blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </Link>
                  <Link className="btn btn-outline-light btn-social" to="#">
                    <i className="fab fa-pinterest"></i>
                  </Link>
                 
                </div><br/>
                <p className="mb-3">
                  <i className="fa fa-envelope me-3"></i>
                  smce.principal@gmail.com
                  <br />
                  <i className="fa fa-envelope me-3"></i>smcengg@yahoo.co.in
                </p>
              </div>  
              <div className="col-lg-6" >
                <h4 className="text-white mb-2">Contact</h4>
                <p className="mb-1">
                  <i className="fa fa-map-marker-alt me-1"></i>
                  <b>Sri Mittapalli College Of Engineering (Autonomous)</b>
                  <br />
                  Tummalapalem, NH16,Guntur, <br />
                  Andhra Pradesh 522233
                
                <p className="mb-1">
                   Mobile - +91 9000447117, <br/> 
                   Mobile - +91 9032727017
                </p>
                </p>
              </div>
              
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start">
              &copy;{" "}
              <Link className="border-bottom" to="#">
                http://www.smce.ac.in
              </Link>
              , All Right Reserved. Designed By{" "}
              <Link className="border-bottom" to="https://htmlcodex.com">
                Adiverse Technologies
              </Link>
            </div>
            {/* <div className="col-md-6 text-center text-md-end">
              <div className="footer-menu">
                <Link to="#">Home</Link>
                <Link to="#">Cookies</Link>
                <Link to="#">Help</Link>
                <Link to="#">FQAs</Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
