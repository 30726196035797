import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/TimeTableTable";
import pdf from "../../assets/pdfsmittapalli/sem-1.pdf";
import pdf2 from "../../assets/pdfsmittapalli/sem-2.pdf";
import StarRateIcon from "@mui/icons-material/StarRate";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Committees = () => {
  const timetable=[
    {
      title:"To frame a suitable time table for conducting theory/ practical subjects as per the University rules."
      
    },
    {
      title:"To attend to various complaints in the timetable and make necessary adjustments."
      
    },
    {
      title:"To scrutinize the workload of the individual faculty members."
    },
    {
      title:"To maintain the records of the time table framed and submit to the IQAC Allocation of lecture halls, laboratory slots without any overlapping etc., effectively."
    },
    
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            TIMETABLE COMMITTEE
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            The time Table committee of SMCE recognizes the importance of time
            and ensures proper utilization for students which makes them
            organized and uses time effectively to maximize chance of success
            hence increasing productivity.
          </p>
          <p>
            The timetable committee to look after the preparation of academic
            routine for B.Tech / M.Tech. / MBA / Diploma classes. The committee
            allows the classes as per the existing JNTU-K and SMCE rules. The
            timetable is framed in such a way that a teacher is assigned classes
            every day
          </p>
          <p>Time table Committee is formed with the following members:</p>
          
        </div>
      </div>
      {/* <div className="container">
        <Table />
      </div> */}
      {/* <iframe src="https://drive.google.com/file/d/1lAyjSt6Ei0SVuYOFCj2wzauz3wMjf6yk/preview?usp=drive_link" width="100%" height="700px"></iframe> */}
      <div className="Course_pdf">
        <div>
          <h4>TIMETABLE COMMITTEE</h4>
          <p>Download the TIMETABLE COMMITTEE</p>
        </div>
        <div>
          <div>
            <a
              href="https://drive.google.com/file/d/1lAyjSt6Ei0SVuYOFCj2wzauz3wMjf6yk/preview?usp=drive_link"
              target="_blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50, color: "#020f4f" }} />
            </a>
          </div>
        </div>
      </div>
     
     
     <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {timetable.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
    </div>
  );
};

export default Committees;
