import React from "react";
import { Link } from "react-router-dom";
import about from "../../assets/SMCE/about.jpg";
import "./About.css";

const About = () => {
  return (
    <>      {/* <h1 className="about_head">
          ABOUT US
        </h1> */}
      <div className="about_main">
        <div className="about_up">
          <div className="aboutmain">
            <div>
              <h1 className="cnt_head">About SMCE</h1>
              <br />
              <div className="main-div">
                <p>
                  Sri Mittapalli College of Engineering was established in 2006
                  with an aim to accomplish distinction in Engineering and
                  Technological pursuits.
                </p>
                <p>
                  Sri Mittapalli College of Engineering approved by AICTE and
                  affiliated to JNTUK, Kakinada is an Autonomous Institution
                  since 2023 and has been accredited by NAAC with 'A+' Grade,
                  NBA and ISO 9001:2015 Certified. It has also been awarded 2(f)
                  status.
                </p>

                <p>
                  Enhanced hands-on Training, well-established infrastructure,
                  updated Software, well-resourced Library,Online Digital
                  Library, Memorandum of Understanding with well-established
                  concerns, Industrial visits, Guest Lectures, Workshops,
                  Symposiums, Conferences and Students Exhibitions, importance
                  given to Sports and Games, Co-curricular and Extra-curricular
                  activities and a crew of quick-witted, proficient and
                  professional Faculty Members are the spring of inspiration for
                  the students to sprout with expertise. The care and concern
                  shown on them by the Secretary, the Vice President, the
                  Principal and the Teaching Members equip them to meet the
                  challenges with ease.
                </p>
                <p>
                  Constant review, exclusive focus given on each student and
                  counseling at the apt time give accessibility to soar high.
                  The inestimable buds bloom here spread fragrance all over the
                  world.
                </p>
              </div>
            </div>

            <div>
              <img className="about_img" src={about} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
