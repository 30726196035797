import React from "react";
import Chairman1 from "../assets/chairmanmi.jpg";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import "./CheairmanText.css";

const Chairman = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row ">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "100px" }}
            >
              <div className="frame">
                <img
                  className="DM_img"
                  src={Chairman1}
                  alt="principal-img"
                 style={{height:"500px"}}
                /><br/>
               <p style={{textAlign:"center"}}>
                    <h4><b>Sri M.V.Koteswara Rao</b></h4>
                    <h6><b>Founder and Chairman</b></h6>
                    </p>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h4 className="section-title bg-white text-start text-primary pe-3">
               <b> Chairman</b>
              </h4>
              

              <div>
                <p className="font_st mb-3">
                
                  I express my gratitude for your interest in Sri Mittapalli
                  College of Engineering. I believe “Everything is possible if
                  our determination is strong and selfless”. My dream of
                  establishing an engineering college to avail the finest
                  science and technical education for rural students had come
                  true in 2006 as Sri Mittapalli Group of Institutions at
                  Tummalapalem in Guntur District and it is affiliated with
                  Jawaharlal Nehru Technological University, Kakinada.
                

                <p className="font_st mb-3">
                  It is my deepest inclination to serve the rural society
                  moulding these technocrats into global business leaders of
                  tomorrow through dedicated staff t and world-class
                  infrastructure. I was immensely pleased to signify that we
                  have imparted an academic program in addition to the
                  engineering curriculum for social, cultural, scientific,
                  economic and technical development.
                </p>
               

                <p className="font_st mb-2">
                  SMCE aims at establishing standard academic excellence and
                  provides integrated training programs for engineering students
                  to meet the challenges in the rapid globalized economic
                  conditions. Thus, our Sri Mittapalli Group of Institutions
                  have earned goodwill from our students and society at large.
                </p>
                </p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chairman;
