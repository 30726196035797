import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./Naac.css";
import { PageHeader } from "../Header/PageHeader";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import { useState } from "react";
import RotatingSpinner from "../Resourses/RotatingSpinner";

//criteria 1
import cr111 from "../../Pdf/NAAC_2023-2024/Criteria1/c1/1.1.1.pdf"
import cr112 from "../../Pdf/NAAC_2023-2024/Criteria1/c1/1.1.2.pdf"
import cr113 from "../../Pdf/NAAC_2023-2024/Criteria1/c1/1.1.3.pdf"
import cr121 from "../../Pdf/NAAC_2023-2024/Criteria1/c2/1.2.1.pdf"
import cr122 from "../../Pdf/NAAC_2023-2024/Criteria1/c2/1.2.2.pdf"
import cr123 from "../../Pdf/NAAC_2023-2024/Criteria1/c2/1.2.3.pdf"
import cr131 from "../../Pdf/NAAC_2023-2024/Criteria1/c3/1.3.1.pdf"
import cr132 from "../../Pdf/NAAC_2023-2024/Criteria1/c3/1.3.2.pdf"
import cr133 from "../../Pdf/NAAC_2023-2024/Criteria1/c3/1.3.3.pdf"
import cr141 from "../../Pdf/NAAC_2023-2024/Criteria1/c4/1.4.1.pdf"
import cr142 from "../../Pdf/NAAC_2023-2024/Criteria1/c4/1.4.2.pdf"




//criteria 2
import cr211 from "../../Pdf/NAAC_2023-2024/Criteria2/c1/2.1.1.pdf"
import cr212 from "../../Pdf/NAAC_2023-2024/Criteria2/c1/2.1.2.pdf"
import cr221 from "../../Pdf/NAAC_2023-2024/Criteria2/c2/2.2.1.pdf"
// import cr222 from "../../Pdf/Naac/criteria2/2.1.1.pdf"
import cr231 from "../../Pdf/NAAC_2023-2024/Criteria2/c3/2.3.1.pdf"
import cr232 from "../../Pdf/NAAC_2023-2024/Criteria2/c3/2.3.2.pdf"
import cr233 from "../../Pdf/NAAC_2023-2024/Criteria2/c3/2.3.3.pdf"
import cr241 from "../../Pdf/NAAC_2023-2024/Criteria2/c4/2.4.1.pdf"
import cr242 from "../../Pdf/NAAC_2023-2024/Criteria2/c4/2.4.2.pdf"
// import cr243 from "../../Pdf/Naac/criteria2/2.1.1.pdf"
import cr251 from "../../Pdf/NAAC_2023-2024/Criteria2/c5/2.5.1.pdf"
import cr252 from "../../Pdf/NAAC_2023-2024/Criteria2/c5/2.5.2.pdf"
import cr261 from "../../Pdf/NAAC_2023-2024/Criteria2/c6/2.6.1.pdf"
import cr262 from "../../Pdf/NAAC_2023-2024/Criteria2/c6/2.6.2.pdf"
import cr263 from "../../Pdf/NAAC_2023-2024/Criteria2/c6/2.6.3.pdf"
import cr271 from "../../Pdf/NAAC_2023-2024/Criteria2/c7/2.7.1.pdf"



//criteria 3
import cr311 from "../../Pdf/NAAC_2023-2024/Criteria3/c1/3.1.1.pdf"
import cr312 from "../../Pdf/NAAC_2023-2024/Criteria3/c1/3.1.2.pdf"
import cr313 from "../../Pdf/NAAC_2023-2024/Criteria3/c1/3.1.3.pdf"
import cr321 from "../../Pdf/NAAC_2023-2024/Criteria3/c2/3.2.1.pdf"
import cr322 from "../../Pdf/NAAC_2023-2024/Criteria3/c2/3.2.2.pdf"
import cr331 from "../../Pdf/NAAC_2023-2024/Criteria3/c3/3.3.1.pdf"
import cr332 from "../../Pdf/NAAC_2023-2024/Criteria3/c3/3.3.2.pdf"
import cr333 from "../../Pdf/NAAC_2023-2024/Criteria3/c3/3.3.3.pdf"
import cr341 from "../../Pdf/NAAC_2023-2024/Criteria3/c4/3.4.1.pdf"
import cr342 from "../../Pdf/NAAC_2023-2024/Criteria3/c4/3.4.2.pdf"
import cr343 from "../../Pdf/NAAC_2023-2024/Criteria3/c4/3.4.3.pdf"
// import cr344 from "../../Pdf/Naac/criteria3/3.4.4 Students Participated in Extension Activities.pdf"
import cr351 from "../../Pdf/NAAC_2023-2024/Criteria3/c5/3.5.1.pdf"
import cr352 from "../../Pdf/NAAC_2023-2024/Criteria3/c5/3.5.2.pdf"




//criteria 4
import cr411 from "../../Pdf/NAAC_2023-2024/Criteria4/c1/4.1.1.pdf"
import cr412 from "../../Pdf/NAAC_2023-2024/Criteria4/c1/4.1.2.pdf"
import cr413 from "../../Pdf/NAAC_2023-2024/Criteria4/c1/4.1.3.pdf"
// import cr414 from "../../Pdf/NAAC_2023-2024/Criteria4/c1/4.1.4.pdf"
import cr421 from "../../Pdf/NAAC_2023-2024/Criteria4/c2/4.2.1.pdf"
// import cr422 from "../../Pdf/NAAC_2023-2024/Criteria4/c1/4.2.2.pdf"
import cr423 from "../../Pdf/NAAC_2023-2024/Criteria4/c2/4.2.3.pdf"
import cr424 from "../../Pdf/NAAC_2023-2024/Criteria4/c2/4.2.4.pdf"
import cr431 from "../../Pdf/NAAC_2023-2024/Criteria4/c3/4.3.1.pdf"
import cr432 from "../../Pdf/NAAC_2023-2024/Criteria4/c3/4.3.2.pdf"
import cr433 from "../../Pdf/NAAC_2023-2024/Criteria4/c3/4.3.3.pdf"
import cr441 from "../../Pdf/NAAC_2023-2024/Criteria4/c4/4.4.1.pdf"
import cr442 from "../../Pdf/NAAC_2023-2024/Criteria4/c4/4.4.2.pdf"



//criteria 5
import cr511 from "../../Pdf/NAAC_2023-2024/Criteria5/c1/5.1.1.pdf"
import cr512 from "../../Pdf/NAAC_2023-2024/Criteria5/c1/5.1.2.pdf"
import cr513 from "../../Pdf/NAAC_2023-2024/Criteria5/c1/5.1.3.pdf"
import cr514 from "../../Pdf/NAAC_2023-2024/Criteria5/c1/5.1.4.pdf"
import cr515 from "../../Pdf/NAAC_2023-2024/Criteria5/c1/5.1.5.pdf"
import cr521 from "../../Pdf/NAAC_2023-2024/Criteria5/c2/5.2.1.pdf"
import cr522 from "../../Pdf/NAAC_2023-2024/Criteria5/c2/5.2.2.pdf"
import cr523 from "../../Pdf/NAAC_2023-2024/Criteria5/c2/5.2.3.pdf"
import cr531 from "../../Pdf/NAAC_2023-2024/Criteria5/c3/5.3.1.pdf"
import cr532 from "../../Pdf/NAAC_2023-2024/Criteria5/c3/5.3.2.pdf"
import cr533 from "../../Pdf/NAAC_2023-2024/Criteria5/c3/5.3.3.pdf"
// import cr541 from "../../Pdf/Aqar_2023-2024/Student Support/5.4/5.4.1.pdf"
// import cr542 from "../../Pdf/Aqar_2023-2024/Student Support/5.4/5.4.2.pdf"


//criteria 6
import cr611 from "../../Pdf/NAAC_2023-2024/Criteria6/c1/6.1.1.pdf"
import cr612 from "../../Pdf/NAAC_2023-2024/Criteria6/c1/6.1.2.pdf"
import cr621 from "../../Pdf/NAAC_2023-2024/Criteria6/c2/6.2.1.pdf"
import cr622 from "../../Pdf/NAAC_2023-2024/Criteria6/c2/6.2.2.pdf"
import cr623 from "../../Pdf/NAAC_2023-2024/Criteria6/c2/6.2.3.pdf"
// import cr623 from "../../Pdf/Naac/criteria6/6.2.3.pdf"
import cr631 from "../../Pdf/NAAC_2023-2024/Criteria6/c3/6.3.1.pdf"
import cr632 from "../../Pdf/NAAC_2023-2024/Criteria6/c3/6.3.2.pdf"
import cr633 from "../../Pdf/NAAC_2023-2024/Criteria6/c3/6.3.3.pdf"
import cr634 from "../../Pdf/NAAC_2023-2024/Criteria6/c3/6.3.4.pdf"
import cr635 from "../../Pdf/NAAC_2023-2024/Criteria6/c3/6.3.5.pdf"
import cr641 from "../../Pdf/NAAC_2023-2024/Criteria6/c4/6.4.1.pdf"
import cr642 from "../../Pdf/NAAC_2023-2024/Criteria6/c4/6.4.2.pdf"
import cr643 from "../../Pdf/NAAC_2023-2024/Criteria6/c4/6.4.3.pdf"
import cr651 from "../../Pdf/NAAC_2023-2024/Criteria6/c5/6.5.1.pdf"
import cr652 from "../../Pdf/NAAC_2023-2024/Criteria6/c5/6.5.2.pdf"
import cr653 from "../../Pdf/NAAC_2023-2024/Criteria6/c5/6.5.3.pdf"




//criteria 7
import cr711 from "../../Pdf/NAAC_2023-2024/Criteria7/c1/7.1.1.pdf"
import cr712 from "../../Pdf/NAAC_2023-2024/Criteria7/c1/7.1.2.pdf"
import cr713 from "../../Pdf/NAAC_2023-2024/Criteria7/c1/7.1.3.pdf"
import cr714 from "../../Pdf/NAAC_2023-2024/Criteria7/c1/7.1.4.pdf"
import cr715 from "../../Pdf/NAAC_2023-2024/Criteria7/c1/7.1.5.pdf"
import cr716 from "../../Pdf/NAAC_2023-2024/Criteria7/c1/7.1.6.pdf"
import cr717 from "../../Pdf/NAAC_2023-2024/Criteria7/c1/7.1.7.pdf"
import cr718 from "../../Pdf/NAAC_2023-2024/Criteria7/c1/7.1.8.pdf"
import cr719 from "../../Pdf/NAAC_2023-2024/Criteria7/c1/7.1.9.pdf"
import cr7110 from "../../Pdf/NAAC_2023-2024/Criteria7/c1/7.1.10.pdf"
import cr7111 from "../../Pdf/NAAC_2023-2024/Criteria7/c1/7.1.11.pdf"
import cr721 from "../../Pdf/NAAC_2023-2024/Criteria7/c2/7.2.1.pdf"
import cr731 from "../../Pdf/NAAC_2023-2024/Criteria7/c3/7.3.1.pdf"
// import cr732 from "../../Pdf/Naac/criteria7/7.3.2 Plan of Action.pdf"

const NaacAQAR_2023 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const handleBase64Data = async (param) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        resources.APPLICATION_URL + "filenames/" + param
      );
      const base64 = response.data;

      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      window.open(url, "_blank");
    } catch (e) {
      console.log("error", e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <RotatingSpinner />
      ) : (
        <div className="container mt-5">
          <PageHeader name="NAAC SELF STUDY REPORT" />
          <div className="crt_main">
            <Accordion className="naac_ssr_criterias">
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Criterion 1 :- Curricular Aspects</Typography>&nbsp;&nbsp;&nbsp;&nbsp;
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        1.1 Curricular Planning and Implementation
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr111} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              1.1.1 The Institution ensures effective curriculum delivery through a well planned and documented process.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr112} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              1.1.2 The institution adheres to the academic calendar including for the conduct of Continuous Internal Evaluation.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr113} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              1.1.3 Teachers of the Institution participate in following activities related to curriculum development and assessment of the affiliating University and/are represented on the following academic bodies 
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        1.2 Academic Flexibility
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr121} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              1.2.1 Number of Programmes in which Choice Based Credit System (CBCS)/ elective course system has been implemented.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr122} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              1.2.2 How many Add on /Certificate programs are added during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr123} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              1.2.3 Number of students enrolled in Certificate/ Add-on programs as against the total number of students.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        1.3 Curriculum Enrichment
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr131} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              1.3.1 Institution integrates crosscutting issues relevant to Professional Ethics, Gender, Human Values, Environment and Sustainability into the Curriculum.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr132} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              1.3.2 Number of courses that include experiential learning through project work/field work/internship.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr133} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              1.3.3 Number of students undertaking project work/field work/ internships.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        1.4 Feedback System
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr141} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              1.4.1 - Institution obtains feedback on the syllabus and its transaction at the institution from the following stakeholders Students, Teachers, Employers, and Alumni.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr142} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              1.4.2 - Feedback process of the Institution.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="naac_ssr_criterias">
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Criterion 2 :- Teaching - Learning And Evaluation</Typography>&nbsp;&nbsp;&nbsp;&nbsp;
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        2.1 Student Enrolment and Profile
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr211} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.1.1 Enrolment Number, Number of students admitted during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr212} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.1.2 Number of seats filled against seats reserved for various categories (SC, ST, OBC), Divyangjan, etc. as per applicable reservation policy during the year.

                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                       
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        2.2 Catering to Student Diversity
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr221} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.2.1 - The institution assesses the learning levels of the students and organizes special Programmes for advanced learners and slow learners.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        {/* <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr222} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.2.2 - Student- Full time teacher ratio (Data for the latest completed academic year).
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails> */}

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        2.3 Teaching- Learning Process
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr231} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.3.1 - Student centric methods, such as experiential learning, participative learning and problem solving methodologies are used for enhancing learning experiences.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr232} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.3.2 - Teachers use ICT enabled tools for effective teaching-learning process.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr233} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.3.3 - Ratio of mentor to students for academic and other related issues.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        2.4 Teacher Profile and Quality
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr241} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.4.1 - Number of full time teachers against sanctioned posts during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr242} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.4.2 Number of full time teachers with Ph. D. / D.M. / M.Ch. /D.N.B Super specialty / D.Sc. / D.Litt. during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        {/* <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr243} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.4.3 - Number of years of teaching experience of full time teachers in the same institution.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails> */}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        2.5. Evaluation Process and Reforms
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr251} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.5.1 - Mechanism of internal assessment is transparent and robust in terms of frequency and mode.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr252} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.5.2 - Mechanism to deal with internal examination related grievances is transparent, time - bound and efficient.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        2.6 Student Performance and Learning Outcome
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr261} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.6.1 - Programme and course outcomes for all Programmes offered by the institution are stated and displayed on website and communicated to teachers and students.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr262} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.6.2 - Attainment of Programme outcomes and course outcomes are evaluated by the institution.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr263} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.6.3 - Pass percentage of Students during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        2.7 Student Satisfaction Survey
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr271} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.7.1 - Student Satisfaction Survey (SSS) on overall institutional performance (Institution design its own questionnaire).
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                </Typography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion className="naac_ssr_criterias">
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Criterion 3 :- Research, Innovations and Extension</Typography>&nbsp;&nbsp;&nbsp;&nbsp;
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        3.1- Resource Mobilization for Research
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr311} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.1.1 Grants received from Government and non-governmental agencies for research projects / endowments in the institution during the year (INR in Lakhs).
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr312} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.1.2 - Number of teachers recognized as research guides (latest completed academic year).
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr313} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.1.3 - Number of departments having Research projects funded by government and non-government agencies during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        3.2 - Research Publication and Awards
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr321} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.2.1 - Institution has created an ecosystem for innovations and has initiatives for creation a transfer of knowledge.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr322} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.2.2 - Number of workshops/seminars conducted on Research Methodology, Intellectual Property Rights (IPR) and entrepreneurship during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        3.3 - Research Publications and Awards
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr331} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.3.1 - Number of Ph.Ds registered per eligible teacher during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr332} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.3.2 - Number of research papers per teachers in the Journals notified on UGC website during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr333} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.3.3 - Number of books and chapters in edited volumes/books published and papers published in national/ international conference proceedings per teacher during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        3.4 - Extension Activities
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr341} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.4.1 - Extension activities are carried out in the neighborhood community, sensitizing student social issues, for their holistic development, and impact thereof during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr342} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.4.2 - Number of awards and recognitions received for extension activities from government/government recognized bodies during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr343} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.4.3 - Number of extension and outreach programs conducted by the institution through NSS/NCC/Red cross/YRC etc., ( including the programmes such as Swachh Bharat, AIDS awareness, Gender issues etc. and/or those organized in collaboration with industry, community and NGOs ) during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        {/* <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr344} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.4.4 - Number of students participating in extension activities at 3.4.3 above during year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails> */}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        3.5 - Collaboration
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr351} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.5.1 - Number of Collaborative activities for research, Faculty exchange, Student exchange internship during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr352} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.5.2 - Number of functional MoUs with institutions, other universities, industries, corporate houses etc. during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className="naac_ssr_criterias">
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Criterion 4 :- Infrastructure and Learning Resources</Typography>&nbsp;&nbsp;&nbsp;&nbsp;
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        4.1 Physical Facilities
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr411} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              4.1.1 - The Institution has adequate infrastructure and physical facilities for teaching- learning viz., classrooms, laboratories, computing equipment etc.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr412} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              4.1.2 - The Institution has adequate facilities for cultural activities, sports, games (indoor, outdoor), gymnasium, yoga centre etc.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr413} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              4.1.3 - Number of classrooms and seminar halls with ICT- enabled facilities such as smart LMS, etc.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        {/* <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr414} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              4.1.4 - Expenditure, excluding salary for infrastructure augmentation during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails> */}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        4.2 Library as a learning Resource
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr421} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              4.2.1 - Library is automated using Integrated Library Management System (ILMS) 
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        {/* <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr422} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              4.2.2 - The institution has subscription for the following e-resources e-journals e-Shodh Sindhu Shodhganga Membership e-books Databases Remote access toe-resources.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails> */}
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr423} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              4.2.3 - Expenditure for purchase of books/e-books and subscription to journals/e- journal during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr424} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              4.2.4 - Number per day usage of library by teachers and students.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        4.3 IT Infrastructure
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr431} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              4.3.1 - Institution frequently updates its IT facilities including Wi-Fi
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr432} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              4.3.2 - Number of Computers
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr433} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              4.3.3 - Bandwidth of internet connection in the Institution.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        4.4 Maintenance of Campus Infrastructure
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr441} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              4.4.1 - Expenditure incurred on maintenance of infrastructure (physical and academic support facilities) excluding salary component during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr442} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              4.4.2 - There are established systems and procedures for maintaining and utilizing physical, academic and support facilities - laboratory, library, sports complex, computers, classrooms.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="naac_ssr_criterias">
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Criterion 5 :- Student Support and Progression</Typography>&nbsp;&nbsp;&nbsp;&nbsp;
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        5.1 Student Support
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr511} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.1 - Number of students benefited by scholarships and free ships provided by the Government during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr512} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.2 - Number of students benefitted by scholarships, free ships etc. provided by the institution / non- government agencies during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr513} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.3 - Capacity building and skills enhancement initiatives taken by the institution include the following: Soft skills Language and communication skills Life skills (Yoga, physical fitness, health and hygiene) ICT/computing skills.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr514} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.4 - Number of students benefitted by guidance for competitive examinations and career counseling offered by the institution during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr515} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.5 - The Institution has a transparent mechanism for timely redressal of student grievances including sexual harassment and ragging cases.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        5.2 Student Progression
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr521} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.2.1 - Number of placement of outgoing students during the year 
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr522} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.2.2 - Number of students progressing to higher education during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr523} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.2.3 - Number of students qualifying in state/national/ international level examinations the year (eg: JAM/CLAT/GATE/ GMAT/CAT/GRE/ TOEFL/ Civil Services/State government examinations).
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        5.3 Student Participation and Activities
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr531} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.3.1 - Number of awards/medals for outstanding performance in sports/cultural activities university/state/national / international level (award for a team event should be counted one) during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr532} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.3.2 - Institution facilitates students’ representation and engagement in various administrative co-curricular and extracurricular activities (student council/ students representation on various bodies as per established processes and norms).
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr533} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.3.3 - Number of sports and cultural events/competitions in which students of the Institution participated during the year (organized by the institution/other institutions).
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>-
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                {/* <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        5.4 Alumni Engagement
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr541} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.4.1 - There is a registered Alumni Association that contributes significantly to the development.
of the institution through financial and/or other support services.

                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr542} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.4.2 - Alumni contribution during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography> */}
              </AccordionDetails>
            </Accordion>
            <Accordion className="naac_ssr_criterias">
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Criterion 6 :- Governance, Leadership and Management</Typography>&nbsp;&nbsp;&nbsp;&nbsp;
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        6.1 Institutional Vision and Leadership 
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr611} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.1.1 The governance of the institution is reflective of and in tune with the vision and mission of the institution..
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr612} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.1.2 The effective leadership is visible in various institutional practices such as decentralization and participative management.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                       
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        6.2 Strategy Development and Deployment
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr621} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.2.1 - The institutional Strategic/ perspective plan is effectively deployed.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr622} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.2.2 - The functioning of the institutional bodies is effective and efficient as visible from policies administrative setup, appointment and service rules, procedures, etc.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        {/* <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr6221} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.2.2.1 Link to Organogram of the Institution Webpage.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails> */}
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr623} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.2.3 - Implementation of e-governance in areas of operation Administration Finance and Accounts Student Admission and Support Examination.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        6.3 Faculty Empowerment Strategies
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr631} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.3.1 - The institution has effective welfare measures for teaching and non- teaching staff.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr632} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.3.2 - Number of teachers provided with financial support to attend conferences/ workshops and towards membership fee of professional bodies during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr633} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.3.3 - Number of professional development /administrative training programs organized the institution for teaching and non-teaching staff during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr634} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.3.4 Number of teachers undergoing online/face-to-face Faculty development Programmes (FDP) during the year
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr635} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.3.5 Institutions Performance Appraisal System for teaching and non- teaching staff.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        6.4 Financial Management and Resource Mobilization
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr641} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.4.1 Institution conducts internal and external financial audits regularly.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr642} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.4.2 Funds / Grants received from non-government bodies, individuals, philanthropers during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr643} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.4.3 Institutional strategies for mobilization of funds and the optimal utilization of resources.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                                    <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        6.5 Internal Quality Assurance System
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr651} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.5.1 - Internal Quality Assurance Cell (IQAC) has contributed significantly for institutionalizing the quality assurance strategies and processes.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr652} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.5.2 The institution reviews its teaching learning process, structures & methodologies of operations and learning outcomes at periodic intervals through IQAC set up as per norms and recorded the incremental improvement in various activities.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr653} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.5.3 - Quality assurance initiatives of the institution include: Regular meeting of Internal Quality Assurance Cell (IQAC); Feedback collected, analyzed and used for improvements Collaborative quality initiatives with other institution(s) Participation in NIRF any other quality audit recognized by state, national or international agencies (ISO Certification, NBA).
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                       
                        
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="naac_ssr_criterias">
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Criterion 7 :- Institutional Values and Best Practices</Typography>&nbsp;&nbsp;&nbsp;&nbsp;
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        7.1 Institutional Values and Social Responsibilities
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr711} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              7.1.1 Measures initiated by the Institution for the promotion of gender equity during the year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr712} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              7.1.2 The Institution has facilities for alternate sources of energy and energy conservation measures.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr713} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              7.1.3 Describe the facilities in the Institution for the management of the following types of degradable and non-degradable waste.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr714} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              7.1.4 Water conservation facilities available in the Institution.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr715} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              7.1.5 Green campus initiatives include.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr716} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              7.1.6 Quality audits on environment and energy are regularly undertaken by the institution.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr717} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              7.1.7 The Institution has Divyangjan-friendly, barrier free environment.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr718} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              7.1.8 Describe the Institutional efforts/initiatives in providing an inclusive environment i.e., tolerance and harmony towards cultural, regional, linguistic, communal socioeconomic and other diversities.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr719} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              7.1.9 Sensitization of students and employees of the Institution to the constitutional obligations: values, rights, duties and responsibilities of citizens.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr7110} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              7.1.10 The Institution has a prescribed code of conduct for students, teachers, administrators and other staff and conducts periodic programmes in this regard.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr7111} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              7.1.11 Institution celebrates / organizes national and international commemorative days, events and festivals.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        7.2 - Best Practices 
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr721} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              7.2. Describe two best practices successfully implemented by the Institution as per NAAC provided in the Manual
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        7.3 - Institutional Distinctiveness
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr731} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              7.3.1 Portray the performance of the Institution in one area distinctive to its priority.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        {/* <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <a href={cr732} target="_blank">
                                <VisibilityIcon />
                                </a>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              7.3.2 Plan of action for the next academic year.
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails> */}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      )}
    </>
  );
};

export default NaacAQAR_2023;
