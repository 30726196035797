import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/HostelTable";
import StarRateIcon from "@mui/icons-material/StarRate";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';



const Committees = () => {
  const hostels =[
    {
      title:"To make surprise visits to the hostels."
    },
    {
     title:"To make suggestions for improvements in the functioning of the hostel and mess in order to maintain cleanliness and discipline."
    },
    {
      title:"To properly maintain hygiene conditions in the hostel."
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
          HOSTEL ADVISORY COMMITTEE
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
          Hostel and Mess committee is responsible for the smooth functioning of the hostel. Mess facilities at Sri Mittapalli College of Engineering.


          </p>
          
        </div>
      </div>
      {/* <div className="container">
        <Table />
      </div> */}
      {/* <iframe src="https://drive.google.com/file/d/114IrdAeQQ7SjCr76b073JcPhTcedNcJO/preview?usp=drive_link" width="98%" height="700px"></iframe> */}
      <div className="Course_pdf">
        <div>
          <h4>HOSTEL ADVISORY COMMITTEE</h4>
          <p>Download the HOSTEL ADVISORY COMMITTEE</p>
        </div>
        <div>
          <div>
            <a
              href="https://drive.google.com/file/d/114IrdAeQQ7SjCr76b073JcPhTcedNcJO/view?usp=drive_link"
              target="_blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50, color: "#020f4f" }} />
            </a>
          </div>
        </div>
      </div>
     
     
     
     
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {hostels.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
     
    </div>
  );
};

export default Committees;
