import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import { PageHeader } from "../../Header/PageHeader";
import "../Departments.css";
import "animate.css";

// Lab Images

import lab3 from "../../../assets/lab/cselab3-3.jpg";
import lab2 from "../../../assets/lab/cselab2-2.jpg";
import lab4 from "../../../assets/lab/cselab1-2.jpg";
import lab1 from "../../../assets/lab/cselab1-1.jpg";
import lab5 from "../../../assets/lab/cselab1-3.jpg";
import lab6 from "../../../assets/lab/cselab2-1.jpg";
import lab7 from "../../../assets/lab/cselab2-3.jpg";
import lab8 from "../../../assets/lab/cselab3-1.jpg";
import lab9 from "../../../assets/lab/cselab3-2.jpg";
import lab10 from "../../../assets/lab/cselab4-1.jpg";
import lab11 from "../../../assets/lab/cselab4-2.jpg";
import lab12 from "../../../assets/lab/cselab4-3.jpg";

import aecs1 from "../../../assets/lab3.jpeg";
import aecs2 from "../../../assets/lab6.jpeg";
import aecs3 from "../../../assets/lab1.jpeg";
// import aecs1 from "../../../assets/";
// import aecs2 from "../../../assets/";
// import aecs3 from "../../../assets/";

// import chem1 from "../../../assets/lab4.jpeg";
// import chem2 from "../../../assets/lab5.jpeg";
// import chem3 from "../../../assets/lab6.jpeg";
import chem1 from "../../../assets/S&H_All_IMG/SH_img1.jpg";
import chem2 from "../../../assets/S&H_All_IMG/SH_img2.jpg";
import chem3 from "../../../assets/S&H_All_IMG/SH_img4.jpg";

// import eng1 from "../../../assets/lab1.jpeg";
// import eng2 from "../../../assets/lab4.jpeg";
// import eng3 from "../../../assets/lab6.jpeg";
import eng1 from "../../../assets/S&H_All_IMG/English_labs.jpg";
import eng2 from "../../../assets/S&H_All_IMG/English_labs.jpg";
import eng3 from "../../../assets/lab6.jpeg";

// import phy1 from "../../../assets/lab1.jpeg";
// import phy2 from "../../../assets/lab5.jpeg";
// import phy3 from "../../../assets/lab3.jpeg";
import phy1 from "../../../assets/S&H_All_IMG/PHY-labs1.jpg";
import phy2 from "../../../assets/S&H_All_IMG/PHY_lab_2.jpg";
import phy3 from "../../../assets/S&H_All_IMG/Phys_lab_img.jpg";

const Labs = () => {
  const [key, setKey] = useState("All");

  return (
    <div className="container">
      <PageHeader name="Laboratories" />
      {/* <Card style={{ width: "100%", margin: "20px 0px" }}>
        <Card.Header>CSE Laboratories</Card.Header>
        <Card.Body>
          <Card.Title>We have Fully Equipped Laboratories</Card.Title>
          <Card.Text>
            we have the several Computer Labs equipped with latest software's
            and workstations. It is connected to internet through a lease line
            to facilitate high speed internet access. All the computers are
            connected through a structured network. Printer and copier
            facilities are also available in the lab. Our dedicated faculty and
            staff are proud of their impressive credentials, which are
            comparable to those of the best career instructors.
          </Card.Text>
        </Card.Body>
      </Card> */}

      <div className="content">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="All" title="All">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={aecs1}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={aecs2}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={aecs3}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={chem1}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={chem2}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={chem3}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                  <br />
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={eng1}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={eng2}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={eng3}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={phy1}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={phy2}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={phy3}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                  <br />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="AECS LAB " title="AECS LAB ">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={aecs1}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={aecs2}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={aecs3}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="ENGINEERING CHEMISTRY LAB "
            title="ENGINEERING CHEMISTRY LAB "
          >
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={chem1}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={chem2}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={chem3}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                  <br />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="ENGLISH LAB " title="ENGLISH LAB ">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={eng1}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={eng2}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={eng3}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="ENGINEERING PHYSICS LAB"
            title="ENGINEERING PHYSICS LAB"
          >
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={phy1}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={phy2}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={phy3}
                    alt="lab-img"
                    style={{ height: "350px", width: "100%" }}
                  />
                  <br />
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Labs;
