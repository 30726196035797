import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/DepartmentAssositaionTable";
import StarRateIcon from "@mui/icons-material/StarRate";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Committees = () => {
  const departmentAss = [
    {
      title:"Various Technical competitions like Lecture, Debate, essay writing, Drawing and Painting, code development, Project Expo,Poster making, etc. are conducted for students."
    },
    {
      title:"Various Indore games like caroms, chess, etc are conducted for students."
    },
    {
      title:"Various Social activities to donate the needs of old age homes,orphanages, schools, etc."
    },
    {
      title:"Design activities to encourage students identify their talent in technical field."
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            DEPARTMENTS ASSOCIATION COMMITTEE
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            The Departmental Associations Committee is the driving force behind
            every department association to enhance the capability of its
            students, academically, socially, culturally and personally. Every
            engineering department of the college has its own department
            association with faculty members.
          </p>
          <p>Departments Association Committee is formed with the following members:</p>
        </div>
      </div>
      {/* <div className="container">
        <Table />
      </div> */}
      {/* <iframe src="https://drive.google.com/file/d/1seJBASAbU6TfEbcb1pQ9M5BIccNNLRTe/preview?usp=drive_link" width="100%" height="700px"></iframe> */}
      <div className="Course_pdf">
        <div>
          <h4>DEPARTMENTS ASSOCIATION COMMITTEE</h4>
          <p>Download the DEPARTMENTS ASSOCIATION COMMITTEE</p>
        </div>
        <div>
          <div>
            <a
              href="https://drive.google.com/file/d/1seJBASAbU6TfEbcb1pQ9M5BIccNNLRTe/preview?usp=drive_link"
              target="_blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50, color: "#020f4f" }} />
            </a>
          </div>
        </div>
      </div>
      
      
      
      
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {departmentAss.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
    </div>
  );
};

export default Committees;
