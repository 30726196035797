import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/BcScStTable";
import StarRateIcon from "@mui/icons-material/StarRate";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Committees = () => {
  const BcScSt = [
    {
      title:"To look after the well being of the welfare of the BC/SC/ST students admitted in the college in various courses."
    },
    {
      title:"Allocation of financial assistance in the form of scholarships for the students and help them to pursue their education."
    },
    {
      title:"To Check and assist the students regarding Scholarships/Reimbursement granted by the Social Welfare Departments, Government of Andhra Pradesh have been received into scholarship account."
      
    },
    {
      title:"In case of any problem relating to Scholarships, the student is required to contact the Scholarships Coordinator first and he will  resolve the problem by taking necessary action."
    },
    {
      title:"The committee creates awareness regarding various schemes created and offered by State Government and Central Government related to  BC/SC/ST Student welfare."
      
    },
      
     
      
      
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            BC, SC & ST STUDENT CELL
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            Backward Castes, Scheduled Castes (SC) and Scheduled Tribes (ST)
            have been identified as the most backward groups of Indian Society.
            They include all the castes, races or tribes, which have been
            socially, economically and educationally backward. The cell has been
            established to support and to bring students from such communities
            in the main stream.
          </p>
          <p>
            The main purpose of the SC/ST cell is to empower the SC/ST students
            in the college. The college takes special interest in facilitating
            financial support to students from these communities from government
            agencies and other sources. They are also encouraged to enrol for
            career orientation programs, which would equip them with the
            necessary skills to choose a career option.
          </p>
          <p>BC, SC & ST Student Cell is formed with the following members:</p>
        </div>
      </div>
      {/* <div className="container">
        <Table />
      </div> */}
      {/* <iframe src="https://drive.google.com/file/d/1_GBRmKa8X53ln1kN0xZz6-EOhFeVQXQz/preview?usp=drive_link" width="100%" height="700px"></iframe> */}
      <div className="Course_pdf">
        <div>
          <h4>BC, SC & ST STUDENT CELL</h4>
          <p>Download the BC, SC & ST STUDENT CELL</p>
        </div>
        <div>
          <div>
            <a
              href="https://drive.google.com/file/d/1_GBRmKa8X53ln1kN0xZz6-EOhFeVQXQz/view?usp=drive_link"
              target="_blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50, color: "#020f4f" }} />
            </a>
          </div>
        </div>
      </div>
    
    
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions of the Finance Committee :</span>
          <p>The Finance Committee shall act as an advisory body to the Governing Body, to consider:</p>
        </p>
        {BcScSt.map((item, index) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>

    </div>
  );
};

export default Committees;
