import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/CareerTable";
import StarRateIcon from "@mui/icons-material/StarRate";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Committees = () => {
  const career = [
    {
      title: "To create awareness among the students regarding abroad education and make the students to attempt GRE, TOEFEL, IELTS."
    },
    {
      title:"Encourage the students to write GATE which is one of the eligibility criteria for government companies and career enhancement."
    },
    {
      title:"Creating, developing and maintaining necessary database of students as required by various companies."
    },
    {
     title:"Visiting companies and highlighting them about our college activities, college facilities, performance level of students etc., including inviting companies to conduct interviews."
    },
    {
      title:"Arranging campus interviews."
    },
    {
      title:"Maintaining necessary lists of students placed through the T&P cell."
    },
    {
      title:"Arranging necessary training programmes and guest lectures."
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            CAREER GUIDANCE CELL
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            The career guidance cell provides relevant academic and career
            information to enable Students to make informed decisions along the
            way. Our focus is to provide learning/training opportunities in the
            areas of academic, career and personal/ Social development and to
            prepare students to meet their future challenges.
          </p>
                 <p>Career Guidance Cell is formed with the following members:</p>
        </div>
      </div>
      {/* <div className="container">
        <Table />
      </div> */}
      {/* <iframe src="https://drive.google.com/file/d/17w8JaB5XavuPDsdcuPO5efFpoB8SPYTW/preview?usp=drive_link" width="98%" height="700px"></iframe> */}
      <div className="Course_pdf">
        <div>
          <h4> CAREER GUIDANCE CELL</h4>
          <p>Download the  CAREER GUIDANCE CELL</p>
        </div>
        <div>
          <div>
            <a
              href="https://drive.google.com/file/d/17w8JaB5XavuPDsdcuPO5efFpoB8SPYTW/view"
              target="_blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50, color: "#020f4f" }} />
            </a>
          </div>
        </div>
      </div>
   
   
   
   
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions of the Finance Committee :</span>
          <p>The Finance Committee shall act as an advisory body to the Governing Body, to consider:</p>
        </p>
        {career.map((item, index) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
    </div>
  );
};

export default Committees;
