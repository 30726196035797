import React from "react";
import "../Committees/Committees.css";
import "../GoverningBody/GoverningBody.css";
import Table from "../CommitteesMenus/IACCTable";
import pdf from "../../assets/pdfsmittapalli/sem-1.pdf";
import pdf2 from "../../assets/pdfsmittapalli/sem-2.pdf";
import StarRateIcon from "@mui/icons-material/StarRate";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Committees = () => {
  const iacc = [
    {
      title:
        "The NBA Co-ordinator will create the awareness about various accreditations and certification’s which are essential and mandatory for the institution. Monitoring the different activities related to the accreditation.",
    },
    {
      title:
        "To create awareness of on outcome-based education to the faculty and the students.",
    },
    {
      title: "To train the department heads on the preparation of SAR.",
    },
    {
      title:
        "To review and prepare the SAR during the course of accreditation.",
    },
    {
      title:
        "To add and adopt best practices as and when stipulated by the NBA.",
    },
    {
      title: "To attend NBA workshops as organized by the authorities.",
    },
  ];
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            INSTITUTE ACCREDITATION’S & CERTIFICATION’S CO-ORDINATOR
          </a>
        </div>
      </div>
      <br />

      {/* <div className="container">
        <Table />
      </div> */}
      {/* <iframe
        src="https://drive.google.com/file/d/1eTjMnyzG2Y3tizMsPCPUTcT1ysvXLsFb/preview"
        width="100%"
        height="700px"
      ></iframe> */}
       <div className="Course_pdf">
        <div>
          <h4>INSTITUTE ACCREDITATION’S & CERTIFICATION’S CO-ORDINATOR</h4>
          <p>Download the INSTITUTE ACCREDITATION’S & CERTIFICATION’S CO-ORDINATOR</p>
        </div>
        <div>
          <div>
            <a
              href="https://drive.google.com/file/d/1eTjMnyzG2Y3tizMsPCPUTcT1ysvXLsFb/view"
              target="_blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50, color: "#020f4f" }} />
            </a>
          </div>
        </div>
      </div>

      <div className="governing_content">
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {iacc.map((item) => (
          <div style={{ display: "flex" }}>
            <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
            <p style={{ textAlign: "start" }}> {item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Committees;
