import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/HealthcenmterTable";

const Committees = () => {
  return (
    <div>
      
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            HEALTH CENTER
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            SMCE provides medicare for all our students and staff. Health care
            facilities for students and staff are felt to be the basic need. A
            doctor has been appointed as College Doctor. The Health Centre
            functions to provide immediate medicinal and first aid to all
            students and staff if the situation arises. The Health Centre is
            well equipped with respect to the availability of basic health
            related facilities like thermometer, sterilizer, auto-clave,
            dressing drum, weight measuring machine, B. P. Apparatus, patient
            bed etc. In case of complications, the patients are referred to
            Katuri medical college and hospital in kodrupadu which is very
            nearer to the institution (5 Km). Transportation is ready 24×7 for
            students’ care.
          </p>
        </div>
      </div>
    </div>
    
    </div>
  );
};

export default Committees;
