import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/RighttoInforamtionTable";
import "../GoverningBody/GoverningBody.css"
import StarRateIcon from "@mui/icons-material/StarRate";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


const Committees = () => {
  const righttoinformation = [
    {
      title:"Particulars of organization, functions and duties."
    },
    {
      title:"Powers and duties of employees. Procedure followed in the decision making process, including channels of supervision and accountability."
    },
    {
      title:"Norms set by it for the discharge of its functions."
    },
    {
      title:"The rules, regulations, instructions, manuals and records, held by it or under its control or used by its employees for discharging its functions."
    },
    {
      title:"Providing information to the students from the public domain,institution domain regarding Academics, Career Opportunities and Skill Development. Statement of the committees and other bodies."
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            RIGHT TO INFORMATION CELL
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
          Right to Information Cell is formed with the following members:

          </p>
          

        </div>
      </div>
      {/* <div className="container">
        <Table />
      </div> */}
      {/* <iframe src="https://drive.google.com/file/d/1CeAkob9lWzSVE_1Baqw-J0raVQ_98R2e/preview?usp=drive_link" width="98%" height="700px"></iframe> */}
      <div className="Course_pdf">
        <div>
          <h4> RIGHT TO INFORMATION CELL</h4>
          <p>RIGHT TO INFORMATION CELL</p>
        </div>
        <div>
          <div>
            <a
              href="https://drive.google.com/file/d/1CeAkob9lWzSVE_1Baqw-J0raVQ_98R2e/preview?usp=drive_link"
              target="_blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50, color: "#020f4f" }} />
            </a>
          </div>
        </div>
      </div>
      
      
      
      
      
      
      
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {righttoinformation.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
    </div>
  );
};

export default Committees;
