import React from "react";
import UGPG from "../../../assets/Academics/UGPG.jpg";
import { Table, Button } from "antd";
import "./Addmission.css";
import PgintakeTable from "./PgintakeTable";

const UGPGIntake = () => {
  const tableData = [
    {
      Programme: "B.Tech – Computer Science and Engineering",
      // UgPgIntake: "240",
      convenerQuota: "294",
      // ManagementQuota: "72",
      BCategory: "126",
      Total:"420",
    },
    {
      Programme: "B.Tech – Computer Science and Engineering(Data Science)",
      // UgPgIntake: "60",
      convenerQuota: "84",
      // ManagementQuota: "18",
      BCategory: "36",
      Total: "120",
    },
    {
      Programme:
        "B.Tech – Computer Science and Engineering(Artificial Intelligence)",
      // UgPgIntake: "120",
      convenerQuota: "84",
      // ManagementQuota: "36",
      BCategory: "36",
      Total: "120",
    },
   
   
    {
      Programme: "B.Tech – Information Technology",
      // UgPgIntake: "60",
      convenerQuota: "42",
      // ManagementQuota: "18",
      BCategory: "18",
      Total: "60",
    },
    {
      Programme: "B.Tech – Electronics and Communication Engineering",
      // UgPgIntake: "63",
      convenerQuota: "63",
      // ManagementQuota: "18",
      BCategory: "27",
      Total: "90",
    },
    // {
    //   Programme:"Electronics & Communication Engineering  (Specialization: VLSI & ES)",
    //   UgPgIntake: "60",
    //   convenerQuota: "13",
    //   // ManagementQuota: "18",
    //   BCategory: "05",
    //   Total:"18",
    // },
    // {
    //   Programme:"Computer Science & Engineering Specialization (CSE) ",
    //   // UgPgIntake: "60",
    //   convenerQuota: "13",
    //   // ManagementQuota: "18",
    //   BCategory: "05",
    //   Total: "18",
      
    // },
   
    // {
    //   Programme: "M.Tech – Computer Science and Engineering",
    //   UgPgIntake: "18",
    //   convenerQuota: "12",
    //   // ManagementQuota: "5",
    //   BCategory: "01",
    //   Total: "60",
    // },
    // {
    //   Programme: "M.Tech – VLSI and Embedded Systems",
    //   UgPgIntake: "18",
    //   convenerQuota: "12",
    //   // ManagementQuota: "5",
    //   BCategory: "01",
    //   Total: "60",
    // },
    // {
    //   Programme: "Master of Business Administration (MBA)",
    //   UgPgIntake: "60",
    //   convenerQuota: "42",
    //   // ManagementQuota: "18",
    //   BCategory: "18",
    //   Total: "60",
    // },

   
    
  ];
  const columns = [
    {
      title: "S.NO",
      // dataIndex: '1',
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      title: "Programme",
      dataIndex: "Programme",
      align: "left",
    },
    // {
    //   title: "UG/PG Intake",
    //   dataIndex: "UgPgIntake",
    //   align: "center",
    // },

    {
      title: "ConvenerQuota",
      dataIndex: "convenerQuota",
      align: "center",
    },
    // {
    //   title: "Management Quota",
    //   dataIndex: "ManagementQuota",
    //   align: "center",
    // },
    {
      title: "BCategory",
      dataIndex: "BCategory",
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "Total",
      align: "center",
    }
  ];
  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };


  return (
    <div>
      <h2 className="addmision_heading">Programmes Offered</h2>
      <div className="intake_img_container">
        <img src={UGPG} alt="UgPg-Intake" className="responsive_image"/>
        <p>
          We offer UG programmes – B.Tech – in Computer Science & Engineering,
          Electronics & Communication Engineering, Information Technology,
          Computer Science & Engineering (Artificial Intelligence) , Computer
          Science & Engineering (Data Science). We also offer PG programmes –
          M.Tech – in Computer Science & Engineering, VLSI and Embedded systems.
        </p>
      </div><br/>

      <h5 style={{textAlign:"center"}}><b>Under Graduation Programs: </b></h5>
      <div className="container">
        <Table columns={columns} dataSource={tableData} onChange={onChange} />
        </div>
      <h5 style={{textAlign:"center"}}><b>Post Graduation Programs: </b></h5>
      <div className="container">
      <PgintakeTable/>
      </div>
       
        {/* <Table columns={columns} dataSource={tableData} onChange={onChange} /> */}
        

      {/* <h5 style={{textAlign:"center", marginLeft:"700px"}}>Total Intake : <b>906</b></h5> */}
      <h5 style={{textAlign:"center"}}><b><blink>* 10% Seats are extra under EWS Quota</blink></b></h5><br/>
    </div>
  );
};

export default UGPGIntake;
