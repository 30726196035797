import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/DevelopmentTable";
import StarRateIcon from "@mui/icons-material/StarRate";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Committees = () => {
  const development = [
    {
      title: "To organize Entrepreneurship Awareness Camps, Entrepreneurship Development Programmes and Faculty Development Programmes in the region for the benefit of S&T persons."
  
    },
    {
      title:"To develop and introduce curriculum on Entrepreneurship Development at various levels including degree/diploma courses of the parent institution and other institutes in the region."
      
    },
    {
      title:"To conduct research work and survey for identifying entrepreneurial opportunities (particularly in S&T areas and Service sector). "
      
    },
    {
      title:"To guide and assist prospective entrepreneurs on various aspects such as preparing project reports, obtaining project approvals, loans and facilities from agencies of support systems and information on various technologies."
    },
    {
      title:"To organize guest lectures, TV & Radio talks, Seminars, etc. for promotion and growth of S& T based and execution of their projects."
    },

  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            ENTREPRENEURSHIP DEVELOPMENT CELL
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
          Entrepreneurship Development Cell (EDC) is established to develop institutional 
          mechanism to create entrepreneurial culture in the institution and to foster techno
           entrepreneurship for generation of wealth and employment by Science &Technology to 
           the students. Its main purpose is to Foster innovation and create an entrepreneurial culture 
           in the institution.
          </p>
          <p>Entrepreneurship Development Cell is formed with the following members:</p>
          
        </div>
      </div>
      {/* <div className="container">
        <Table />
      </div> */}
      {/* <iframe src="https://drive.google.com/file/d/1wjvqtBdst1b0Lan3vbCLYsZYxCtFP7Qb/preview?usp=drive_link" width="100%" height="700px"></iframe> */}
      <div className="Course_pdf">
        <div>
          <h4>ENTREPRENEURSHIP DEVELOPMENT CELL</h4>
          <p>Download the ENTREPRENEURSHIP DEVELOPMENT CELL</p>
        </div>
        <div>
          <div>
            <a
              href="https://drive.google.com/file/d/1wjvqtBdst1b0Lan3vbCLYsZYxCtFP7Qb/view?usp=drive_link"
              target="_blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50, color: "#020f4f" }} />
            </a>
          </div>
        </div>
      </div>
      
      
      
      
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {development .map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
    </div>
  );
};

export default Committees;
