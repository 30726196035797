import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/AluminiTable";
import pdf from "../../assets/pdfsmittapalli/sem-1.pdf";
import pdf2 from "../../assets/pdfsmittapalli/sem-2.pdf";
import StarRateIcon from "@mui/icons-material/StarRate";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
const Committees = () => {
  const alumini =[
    {
      title:"Prescribing guide lines for the various activities of the association, Selecting Association student members periodically."
    },
    {
      title:"Publishing and circulating news letter."
    },
    {
      title:"Conducting alumni meetings."
    },
    {
      title:"Inviting alumni students to share their industry experience and render their helps in inviting companies for placements and experts for guest lectures."
    },

  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            ALUMNI ASSOCIATION COMMITTEE
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
          The concept of alumni association is to provide a forum to bringing together the old students of 
          the College, so that they can contribute their expertise in technology to help each other 
          for achieving the goal. Sri Mittapalli College of Engineering is proud to have as its alumni a 
          collective of good engineers over the past 17 years. This number will continue to grow.

          </p>
          
          <p style={{fontWeight:"bold"}}>Alumni Association Committee is formed with the following members:</p>
        </div>
      </div>
      {/* <div className="container">
        <Table />
      </div> */}
      {/* <iframe src="https://drive.google.com/file/d/1y95PJLladBGP-rL8gqXjkL0jldTCf-l3/preview?usp=drive_link" width="100%" height="700px"></iframe> */}
      <div className="Course_pdf">
        <div>
          <h4>ALUMNI ASSOCIATION COMMITTEE</h4>
          <p>Download the ALUMNI ASSOCIATION COMMITTEE</p>
        </div>
        <div>
          <div>
            <a
              href="https://drive.google.com/file/d/1y95PJLladBGP-rL8gqXjkL0jldTCf-l3/view?usp=drive_link"
              target="_blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50, color: "#020f4f" }} />
            </a>
          </div>
        </div>
      </div>
     
     
     
     
     
     
     
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions of the Finance Committee :</span>
          <p>The Finance Committee shall act as an advisory body to the Governing Body, to consider:</p>
        </p>
        {alumini.map((item, index) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>

      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
          Alumni registration Certificate

          </a>
          <button type="button" className="btn_sem">
              <a href={pdf} target="blank">
               Click Here{" "}
              </a>
              </button>
        </div>
      </div>
    </div>
  );
};

export default Committees;
