import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/AcadamicTable";
import StarRateIcon from "@mui/icons-material/StarRate";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const Committees = () => {
  const academicCollege = [
    {
      title:
        "Scrutinize and approve the proposals with or without modification of the Boards of Studies with regard to courses of study, academic regulations, curricula, syllabi and modifications thereof, instructional and evaluation arrangements, methods, procedures relevant thereto etc., provided that where the Academic Council differs on any proposal, it shall have the right to return the matter for reconsideration to the Board of Studies concerned or reject it, after giving reasons to do so.",
    },
    {
      title:
        "Make regulations regarding the admission of students to different programmes of study in the college keeping in view the policy of the Government.",
    },
    {
      title:
        "Make regulations for sports, extra-curricular activities, and proper maintenance and functioning of the playgrounds and hostels.",
    },
    {
      title:
        "Recommend to the Governing Body proposals for institution of new programmes of study.",
    },
    {
      title:
        "Recommend to the Governing Body institution of scholarships, studentships, fellowships, prizes and medals, and to frame regulations for the award of the same.",
    },
    {
      title:
        "Advise the Governing Body on suggestions(s) pertaining to academic affairs made by it.",
    },
    {
      title:
        "Perform such other functions as may be assigned by the Governing Body.",
    },
  ];
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            College Academic Committee
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          {/* <p>
            Subject to the control and approval of the Governing Body, the
            College Academic Council is responsible for the academic quality and
            standards of the college. It is also responsible for the admission
            and regulation of students.
          </p> */}
        </div>
      </div>
      {/* <div className="container">
        <Table />
      </div> */}
      {/* <iframe src="https://drive.google.com/file/d/1xks0-ES3TrA370uNTGZuy9WkJZ9RgI3d/preview" width="100%" height="700px"></iframe> */}
      <div className="Course_pdf">
        <div >
          <h4>College Academic Committee</h4>
          <p>
            Download the College Academic Committee
          </p>
        </div>
        <div>
          <div>
            <a
              href="https://drive.google.com/file/d/1xks0-ES3TrA370uNTGZuy9WkJZ9RgI3d/view"
              target="_blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50,color:"#020f4f" }} />
            </a>
          </div>
        </div>
      </div>

      <div className="governing_content">
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {academicCollege.map((item) => (
          <div style={{ display: "flex" }}>
            <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
            <p style={{ textAlign: "start" }}> {item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Committees;
