import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/IqacTable";
import pdf from "../../assets/pdfsmittapalli/sem-1.pdf";
import pdf2 from "../../assets/pdfsmittapalli/sem-2.pdf";
import StarRateIcon from "@mui/icons-material/StarRate";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
const Committees = () => {
  const iqac = [
    {
      title:
        "Dissemination of information on various quality parameters of higher education. Acting as a nodal agency of the Institution for coordinating quality-related",
    },
    {
      title: "Development of quality concerned culture in the",
    },
    {
      title:
        "Development and application of quality benchmarks/parameters for the various academic and administrative activities of the Institute.",
    },
    {
      title:
        "Facilitating the creation of a learner-centric environment conducive for quality education and faculty maturation to adopt the required knowledge and technology for participatory teaching and learning process.",
    },
    {
      title:
        "Arrangement for feedback responses from students, parents and other stakeholders on quality-related institutional processes.",
    },
    {
      title:
        "Organization of inter and intra institutional workshops, seminars on quality related themes and promotion of quality circles.",
    },
    {
      title:
        "Documentation of the various programmes/activities of the Institute, leading to quality improvement.",
    },
  ];
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            International Quality Assurance Cell
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            IQAC is the Internal Quality Assurance Cell that is formed to ensure
            the best practices in the institution.
          </p>
        </div>
      </div>
      {/* <div className="container">
        <Table />
      </div> */}
      {/* <iframe src="https://drive.google.com/file/d/1xks0-ES3TrA370uNTGZuy9WkJZ9RgI3d/preview" width="100%" height="700px"></iframe> */}
      <div className="Course_pdf">
        <div>
          <h4>International Quality Assurance Cell</h4>
          <p>Download the International Quality Assurance Cell Committee</p>
        </div>
        <div>
          <div>
            <a
              href="https://drive.google.com/file/d/1xbAPrZ0e_IojYYx83fknKtezkvysvLLr/view?usp=drive_link"
              target="_blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50, color: "#020f4f" }} />
            </a>
          </div>
        </div>
      </div>
      <div className="governing_content">
        <p>
          <span className="governing_sidehead">
            Functions of the Finance Committee :
          </span>
          <p>
            The Finance Committee shall act as an advisory body to the Governing
            Body, to consider:
          </p>
        </p>
        {iqac.map((item, index) => (
          <div style={{ display: "flex" }}>
            <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
            <p> {item.title}</p>
          </div>
        ))}
      </div>
      <div className="container">
        <div class="navbar navbar-expand-lg navbar-dark bg-dark">
          <div class="container">
            <a class="navbar-brand" href="#">
              IQAC Minutes
            </a>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-6">
              <button type="button" className="btn_sem">
                <a href={pdf} target="blank">
                  2021-22 Semister-1{" "}
                </a>
              </button>
            </div>
            <div className="col-6">
              <button type="button" className="btn_sem">
                <a href={pdf2} target="blank">
                  2021-22 Semister-2{" "}
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Committees;
