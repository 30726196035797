import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/CanteenTable";
import { Title } from "@mui/icons-material";
import StarRateIcon from "@mui/icons-material/StarRate";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';



const Committees = () => {
  const canteen =[
    {
      title:"To supervise, take steps for the maintenance of canteen facilities with"
    },
    {
      title:"To maintain and control the quality of food supplied in the   "
    },
    {
      title:"To modernize the canteen equipment and cooking"
    },
    {
      title:"To control and make suggestions to the canteen"
    },
    {
      title:"To plan and monitor the maintenance of all the infrastructure facilities related to"
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">CANTEEN COORDINATION COMMITTEE

          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
          The Committee monitors, at regular intervals, the quality and quantity of services provided by 
          the canteen and works together with the canteen in charge to improve the services with regards 
          to quality of the food provided, timing of canteen, prices of articles sold.
          </p>
          <p>Canteen Coordination Committee is formed with the following members.</p>
          
        
         
        </div>
      </div>
      {/* <div className="container">
        <Table />
      </div> */}
       {/* <iframe src="https://drive.google.com/file/d/1HH_ZfMFVvwvhmoicOZ-wYDdfzCrclaU1/preview?usp=drive_link" width="98%" height="700px"></iframe> */}
       <div className="Course_pdf">
        <div>
          <h4>CANTEEN COORDINATION COMMITTEE</h4>
          <p>Download the CANTEEN COORDINATION COMMITTEE</p>
        </div>
        <div>
          <div>
            <a
              href="https://drive.google.com/file/d/1HH_ZfMFVvwvhmoicOZ-wYDdfzCrclaU1/view?usp=drive_link"
              target="_blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50, color: "#020f4f" }} />
            </a>
          </div>
        </div>
      </div>
     
     
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {canteen.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
      
     
    </div>
  );
};

export default Committees;
