import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/CulturalTable";
import StarRateIcon from "@mui/icons-material/StarRate";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
const Committees = () => {
  const culture =[
    {
      title:"To provide platform to students to exhibit their talents."
    },
    {
      title:"To provide entertainment to students."
    },
    {
      title:"To showcase their talents to outside world to get recognition."
    },
    {
      title:"To promote a dynamic cultural heritage that is preserved, used and developed."
      
    },
    {
      title:"To inculcate leadership qualities among students."
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            CULTURAL COMMITTEE
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            The Cultural Committee shall be responsible for all intra and inter
            collegiate cultural events in the College. To plan and schedule
            cultural events for the students. To encourage the students to
            participate in cultural activities.
          </p>
          <p>Cultural Committee is formed with the following members: </p>
          
        </div>
      </div>
      {/* <div className="container">
        <Table />
      </div> */}
      {/* <iframe src="https://drive.google.com/file/d/1IxiuMANZFhkuyHMGzS7wFhF_r2CdPBdG/preview?usp=drive_link" width="100%" height="700px"></iframe> */}
      <div className="Course_pdf">
        <div>
          <h4>CULTURAL COMMITTEE</h4>
          <p>Download the CULTURAL COMMITTEE</p>
        </div>
        <div>
          <div>
            <a
              href="https://drive.google.com/file/d/1IxiuMANZFhkuyHMGzS7wFhF_r2CdPBdG/view?usp=drive_link"
              target="_blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50, color: "#020f4f" }} />
            </a>
          </div>
        </div>
      </div>
      
      
      
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {culture.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
    </div>
  );
};

export default Committees;
